const Link = {
    baseStyle: {
        color: "whiteAlpha.900",
        _focus: {
            boxShadow: "none"
        }
    }
}

export default Link
